import React, { useEffect, useMemo, useRef, useState } from "react";
import { MapContainer as LeafletMap, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LeafletMouseEvent, icon } from "leaflet";
import myMarkerIcon from "./map-marker.png";

const MapContainer = ({
  latitude,
  longitude,
  onMarkerClick,
  onMarkerMove,
}: {
  latitude: number;
  longitude: number;
  onMarkerClick: (event: LeafletMouseEvent) => void;
  onMarkerMove?: (latLng: any) => void;
}) => {
  const [position, setPosition] = useState({ lat: 38.707519, lng: -9.13644 });
  const markerRef = useRef(null);

  const myIcon = icon({
    iconUrl: myMarkerIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const onMarkerMoves = (latLng: any) => {
    if (onMarkerMove) {
      onMarkerMove(latLng);
    }
  };

  useEffect(() => {
    setPosition({
      lat: latitude || position.lat,
      lng: longitude || position.lng,
    });
  }, [latitude, longitude]);

  const eventHandlers = useMemo(
    () => ({
      dragend(e: {
        target: {
          getLatLng: () => React.SetStateAction<{ lat: number; lng: number }>;
        };
      }) {
        onMarkerMoves(e.target.getLatLng());
      },
      click: onMarkerClick,
    }),
    [onMarkerClick, onMarkerMoves]
  );

  return (
    <LeafletMap
      center={position}
      zoom={13}
      style={{
        height: "97%",
        width: "100%",
        borderRadius: "10px",
        overflow: "hidden",
      }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker
        position={position}
        draggable={true}
        eventHandlers={eventHandlers}
        ref={markerRef}
        icon={myIcon}
      ></Marker>
    </LeafletMap>
  );
};

export default MapContainer;
