import React, { useRef, useState } from "react";
import {
  Table,
  Input,
  Button,
  Space,
  Modal,
  Card,
  Row,
  Col,
  notification,
  Slider,
  InputNumber,
} from "antd";
import type { InputRef, TableColumnType, TableColumnsType } from "antd";
import MapContainer from "./components/MapContainer";
import "./App.css";
import { LeafletMouseEvent } from "leaflet";
import { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";
import { Footer } from "antd/es/layout/layout";
import { Excel } from "antd-table-saveas-excel";
import { IExcelColumn } from "antd-table-saveas-excel/app";
import { format } from "date-fns";

function App() {
  const [latitude, setLatitude] = useState<any | null>("38.707519");
  const [longitude, setLongitude] = useState<any | null>("-9.13644");
  const [range, setRange] = useState(30);
  const [nearestLocations, setNearestLocations] = useState<Location[]>([]);
  const [searchText, setSearchText] = useState("");
  const [searchTextFilter, setSearchTextFilter] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [reportData, setReportData] = useState<Location | null>(null);
  const [reportDetails, setReportDetails] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [reportProblemDetails, setReportProblemDetails] = useState("");
  const [helpModalVisible, setHelpModalVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [donationModalVisible, setDonationModalVisible] = useState(false);
  const CoffeeImage =
    "https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png";
  const searchInput = useRef<InputRef>(null);
  const dot = "https://be.fyxus.xyz";
  //const dot = "http://localhost:8080";

  interface Location {
    name: string;
    concelho: string;
    qzp_novo: string;
    qzp_antigo: string;
    codigo: string;
    observacoes: string;
    agrupamento: string;
    distance: number;
  }

  type LocationIndex = keyof Location;

  const handleMapClick = (event: LeafletMouseEvent) => {
    setLatitude(event.latlng.lat);
    setLongitude(event.latlng.lng);
  };

  const handleMarkerMove = (latLng: { lat: any; lng: any }) => {
    setLatitude(latLng.lat);
    setLongitude(latLng.lng);
  };

  const handleGetNearest = async () => {
    if (!latitude || !longitude) {
      notification.info({
        message: "Dados Insuficientes",
        description: "Por favor, selecione uma localização no mapa.",
        duration: 3,
      });
      return;
    }
    try {
      const response = await fetch(
        `${dot}/api/v1/nearest?lat=${latitude}&long=${longitude}`
      );
      const data = await response.json();
      setNearestLocations(data);
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao buscar localizações mais próximas",
        duration: 3,
      });
    }
  };

  const handleGetLocationsInZone = async () => {
    if (!latitude || !longitude || !range) {
      notification.info({
        message: "Dados Insuficientes",
        description: "Por favor, selecione uma localização e insira uma faixa",
        duration: 3,
      });
      return;
    }
    try {
      const response = await fetch(
        `${dot}/api/v1/zone?lat=${latitude}&long=${longitude}&range=${range}`
      );
      const data = await response.json();
      setNearestLocations(data);
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao buscar localizações na zona",
        duration: 3,
      });
    }
  };

  const handleSearch = async () => {
    if (!searchText) {
      notification.info({
        message: "Dados Insuficientes",
        description: "Por favor, insira um endereço para pesquisa.",
        duration: 3,
      });
      return;
    }
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?q=${searchText}&format=json&limit=1`
      );
      const data = await response.json();
      if (data.length) {
        const { lat, lon } = data[0];
        setLatitude(lat);
        setLongitude(lon);
      } else {
        notification.error({
          message: "Endereço não encontrado.",
          description: "Por favor, insira um endereço válido.",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao buscar endereço",
        duration: 3,
      });
    }
  };

  const handleSearchFilter = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: LocationIndex
  ) => {
    confirm();
    setSearchTextFilter(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchTextFilter("");
  };

  const handleOpenReportModal = () => {
    setReportModalVisible(true);
  };

  const handleCloseReportModal = () => {
    setReportModalVisible(false);
    setReportProblemDetails("");
    setConfirmLoading(false);
  };

  const handleSubmitProblemReport = async () => {
    try {
      if (!reportProblemDetails.trim()) {
        return;
      }
      setConfirmLoading(true);

      const response = await fetch(`${dot}/api/v1/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location: "App",
          details: reportProblemDetails,
          infoType: "2",
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          handleCloseReportModal();
        }, 2000);
        notification.success({
          message: "Problema Reportado",
          description: "O problema foi reportado com sucesso.",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Erro",
          description: "Erro ao reportar problema.",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao reportar problema." + error,
        duration: 3,
      });
    } finally {
      setReportProblemDetails("");
      setReportModalVisible(false);
      setConfirmLoading(false);
    }
  };

  const getColumnSearchProps = (
    dataIndex: LocationIndex
  ): TableColumnType<Location> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchFilter(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearchFilter(selectedKeys as string[], confirm, dataIndex)
            }
            size="small"
            style={{ width: 90 }}
          >
            Filtrar
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearchFilter([], confirm, dataIndex); // Reset filter as well
            }}
            size="small"
            style={{ width: 90 }}
          >
            Apagar
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fechar
          </Button>
        </Space>
      </div>
    ),
    onFilter: (value, record) => {
      // Check if dataIndex is "qzp_novo" column, then perform exact match filtering
      if (dataIndex === "qzp_novo") {
        return record[dataIndex].toString() === value.toString();
      }
      // For other columns, perform default filtering logic
      return record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    },
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchTextFilter]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns: TableColumnsType<Location> = [
    {
      title: "Código",
      dataIndex: "codigo",
      sorter: (a, b) => a.codigo.localeCompare(b.codigo),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Agrupamento",
      dataIndex: "agrupamento",
      ...getColumnSearchProps("agrupamento"),
    },
    {
      title: "Concelho",
      dataIndex: "concelho",
      ...getColumnSearchProps("concelho"),
    },
    {
      title: "QZP Novo",
      dataIndex: "qzp_novo",
      sorter: (a, b) => a.qzp_novo.localeCompare(b.qzp_novo),
      sortDirections: ["ascend", "descend"],
      ...getColumnSearchProps("qzp_novo"),
    },
    {
      title: "QZP Antigo",
      dataIndex: "qzp_antigo",
      ...getColumnSearchProps("qzp_antigo"),
    },
    {
      title: "Observações",
      dataIndex: "observacoes",
      ...getColumnSearchProps("observacoes"),
    },
    {
      title: "Distância",
      dataIndex: "distance",
      sorter: (a, b) => a.distance - b.distance,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Abrir no Google Maps",
      dataIndex: "maps",
      render: (_, record) => (
        <Button
          type="link"
          style={{
            background: "#4285F4",
            color: "#fff",
            border: "1px solid #4285F4",
            borderRadius: "4px",
            padding: "5px 10px",
            cursor: "pointer",
            textDecoration: "none",
          }}
          onClick={() => handleOpenGoogleMaps(record)}
        >
          Abrir no Google Maps
        </Button>
      ),
    },
    {
      title: "Reportar erro",
      dataIndex: "report",
      render: (_, record) => (
        <Button type="link" onClick={() => handleReport(record)}>
          Reportar erro
        </Button>
      ),
    },
  ];

  const excelColumns: IExcelColumn[] = [
    {
      title: "Código",
      dataIndex: "codigo",
    },
    {
      title: "Agrupamento",
      dataIndex: "agrupamento",
    },
    {
      title: "Concelho",
      dataIndex: "concelho",
    },
    {
      title: "QZP Novo",
      dataIndex: "qzp_novo",
    },
    {
      title: "QZP Antigo",
      dataIndex: "qzp_antigo",
    },
    {
      title: "Observações",
      dataIndex: "observacoes",
    },
    {
      title: "Distância",
      dataIndex: "distance",
    },
  ];

  const handleTextAreaChange = (event: any) => {
    setReportDetails(event.target.value);
  };

  const handleReport = (record: Location) => {
    setReportData(record);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setReportData(null);
    setReportDetails("");
    setConfirmLoading(false);
  };

  const handleSubmitReport = async () => {
    setConfirmLoading(true);
    if (!reportData) {
      // No report data to submit
      return;
    }

    try {
      const response = await fetch(`${dot}/api/v1/report`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          location:
            reportData.name +
            ", " +
            reportData.concelho +
            ", " +
            reportData.qzp_novo,
          details: reportDetails,
          infoType: "1",
        }),
      });

      if (response.ok) {
        setTimeout(() => {
          handleModalClose();
        }, 2000);
        notification.success({
          message: "Report Submetido",
          description: "O report foi submetido com sucesso.",
          duration: 3,
        });
      } else {
        notification.error({
          message: "Erro",
          description: "Erro ao submeter report.",
          duration: 3,
        });
      }
    } catch (error) {
      notification.error({
        message: "Erro",
        description: "Erro ao submeter report." + error,
        duration: 3,
      });
    } finally {
      handleCloseReportModal();
    }
  };

  const handleExportClick = (filtered: boolean) => {
    const excel = new Excel();

    const currentDate = format(new Date(), "yyyyMMddHHmmss");
    const fileName = `School_${currentDate}.xlsx`;

    if (filtered) {
      excel
        .addSheet("Escolas")
        .addColumns(excelColumns)
        .addDataSource(filteredData, {
          str2Percent: true,
        })
        .saveAs(fileName);
      return;
    } else {
      excel
        .addSheet("Escolas")
        .addColumns(excelColumns)
        .addDataSource(organizeData(), {
          str2Percent: true,
        })
        .saveAs(fileName);
    }
  };

  const handleHelpModalOpen = () => {
    setHelpModalVisible(true);
  };

  const handleHelpModalClose = () => {
    setHelpModalVisible(false);
  };

  const handleDonationModalClose = () => {
    setDonationModalVisible(false);
  };

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    if (extra.action === "filter") {
      setFilteredData(extra.currentDataSource);
    }
  };

  const handleOpenGoogleMaps = async (record: Location) => {
    if (record.codigo != null && record !== undefined) {
      try {
        const response = await fetch(
          `${dot}/api/v1/location?lat=${latitude}&long=${longitude}&codigo=${record.codigo}`
        );
        const data = await response.json();

        const googleMapsURL = data.google_maps_url;

        window.open(googleMapsURL, "_blank");
      } catch (error) {
        // Handle errors
        notification.error({
          message: "Erro",
          description: "Erro ao abrir no Google Maps",
          duration: 3,
        });
      }
    }
  };

  const organizeData = () => {
    return nearestLocations.map((location, index) => ({
      ...location,
      key: index,
    }));
  };

  const footerStyle: React.CSSProperties = {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#fff",
  };

  return (
    <>
      <div className="App">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={16}>
            <div className="map-container">
              <MapContainer
                latitude={latitude}
                longitude={longitude}
                onMarkerClick={handleMapClick}
                onMarkerMove={handleMarkerMove}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className="sidebar">
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Card title="Opções de Localização">
                    <div className="group">
                      <Input
                        placeholder="Insira o endereço para pesquisar"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                      <Button type="primary" onClick={handleSearch}>
                        Pesquisar
                      </Button>
                    </div>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Card title="Opções de Pesquisa">
                    <div className="group">
                      Distancia por Zona
                      <Row>
                        <Col span={8}>
                          <InputNumber
                            min={1}
                            max={600}
                            style={{ margin: "0 16px" }}
                            value={range || 0}
                            onChange={(e) => setRange(e as number)}
                          />
                        </Col>
                        <Col span={16}>
                          <Slider
                            min={1}
                            max={600}
                            onChange={(value) => setRange(value)}
                            value={typeof range === "number" ? range : 0}
                          />
                        </Col>
                      </Row>
                      <Button type="primary" onClick={handleGetNearest}>
                        Obter Localizações Mais Próximas
                      </Button>
                      <Button type="primary" onClick={handleGetLocationsInZone}>
                        Obter Localizações na Zona
                      </Button>
                      <h5>Informação importante</h5>
                      <div style={{ color: "black" }}>
                        As distâncias são calculadas para serem em linha reta.
                        Haveria outras formas de fazê-lo utilizando estradas, no
                        entanto, isso seria muito dispendioso a nível monetário.
                        Sendo este um trabalho simples e barato, não penso que
                        seja viável.
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col xs={24}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <Button
                        type="primary"
                        onClick={handleHelpModalOpen}
                        block
                      >
                        Ver Instruções
                      </Button>
                    </Col>
                    <Col xs={24}>
                      <Button
                        type="primary"
                        onClick={() => handleExportClick(false)}
                        disabled={organizeData().length <= 0}
                        block
                      >
                        Exportar dados não Filtrados
                      </Button>
                    </Col>
                    <Col xs={24}>
                      <Button
                        type="primary"
                        onClick={() => handleExportClick(true)}
                        disabled={filteredData.length <= 0}
                        block
                      >
                        Exportar dados Filtrados
                      </Button>
                    </Col>
                    <Col xs={24}>
                      <Button
                        type="primary"
                        onClick={handleOpenReportModal}
                        block
                      >
                        Problemas com a aplicação? Reporte um problema
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={organizeData()}
          onChange={handleTableChange}
        />
        <Modal
          title="Reportar Informação"
          open={reportModalVisible}
          onCancel={handleCloseReportModal}
          onOk={handleSubmitProblemReport}
          confirmLoading={confirmLoading}
        >
          <Input.TextArea
            placeholder="Descreva o problema aqui..."
            value={reportProblemDetails}
            onChange={(e) => setReportProblemDetails(e.target.value)}
          />
        </Modal>
        <Modal
          title="Reportar Informação"
          open={modalVisible}
          onCancel={handleModalClose}
          onOk={handleSubmitReport}
          confirmLoading={confirmLoading}
        >
          {reportData && (
            <div>
              <p>Nome: {reportData.name}</p>
              <p>Concelho: {reportData.concelho}</p>
              <p>QZP Novo: {reportData.qzp_novo}</p>
              <p>Detalhes:</p>
              <Input.TextArea
                placeholder="Descreva os problemas/erros que encontrou aqui..."
                value={reportDetails}
                onChange={handleTextAreaChange}
              />
            </div>
          )}
        </Modal>
        <Modal
          title="Instruções"
          open={helpModalVisible}
          footer={null}
          onCancel={handleHelpModalClose}
        >
          <p>
            &ensp;Para pesquisar um endereço, insira o endereço no campo de
            pesquisa e clique no botão "Pesquisar".
            <div style={{ color: "red" }}>
              &ensp;Possíveis moradas/localizações podem não existir, por favor
              procure um local mais próximo.
            </div>
          </p>
          <p>
            &ensp;Para obter localizações mais próximas, arraste o ponteiro no
            mapa ou pesquise e clique no botão "Obter Localizações Mais
            Próximas".
          </p>
          <p>
            &ensp;Para obter localizações em uma zona, arraste o ponteiro no
            mapa ou pesquise, arraste em "Distancia por Zona" o ponteiro para a
            distancia pretendida e clique no botão "Obter Localizações na Zona".
          </p>
          <p>
            &ensp;Para exportar todos os dados para um arquivo Excel, clique no
            botão "Exportar dados não Filtrados".
            <div>
              &ensp;Para exportar os dados filtrados na tabela abaixo, clique no
              botão "Exportar dados Filtrados".
            </div>
            <div>
              &ensp;Apenas os dados exibidos na tabela serão exportados desse
              modo apenas quando houver dados na tabela ficara disponivel.
            </div>
          </p>
          <p>
            &ensp;Para reportar um problema, clique no botão "Problemas com a
            aplicação? Reporte um problema".
          </p>
          <p>
            &ensp;Para reportar um erro numa localização, clique no botão
            "Reportar erro" na tabela.
            <div style={{ color: "red" }}>
              &ensp;Algumas localizações podem estar erradas. Por favor,
              verifique.
            </div>
            &ensp;Ao reportar um erro, por favor, forneça o máximo de detalhes
            possível.
          </p>
          <p>
            &ensp;Na tabela tem a opção de filtrar os dados, basta clicar no
            ícone de filtro e inserir o que deseja filtrar, ou então clicar no
            ↕️ para ordenar os dados.
          </p>
        </Modal>
        <Modal
          title="Formas de doação"
          open={donationModalVisible}
          footer={null}
          onCancel={handleDonationModalClose}
        >
          <h4>MBWAY:</h4>
          <div>917601519</div>
          <p>
            <h4>Entidade e Referencia:</h4>
            <div>Entidade: 21 312</div>
            <div>Referencia: 516 179 144</div>
          </p>
          <div>
            <h4>Outras alternativas:</h4>
            <a href="https://www.buymeacoffee.com/fpaivafyxus">
              <img
                src={CoffeeImage}
                alt="Buy me a coffee"
                style={{ width: "150px" }}
              />
            </a>
          </div>
        </Modal>
      </div>
      <Footer style={footerStyle}>
        <div style={{ color: "black" }}>
          Made with ❤️ and 🚀 by Francisco Paiva
        </div>
        <div style={{ color: "black" }}>
          Espero que vos ajude nesta longa jornada
        </div>
        <div
          style={{
            color: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "8px" }}>
            Se gostou da aplicação, considere fazer uma doação
          </div>
          <Button type="primary" onClick={() => setDonationModalVisible(true)}>
            Doar
          </Button>
        </div>
      </Footer>
    </>
  );
}

export default App;
